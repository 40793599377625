<template>
  <div class="middlealign text-container">
    <h1>Privacy Policy</h1>
    <p><i>Part</i> of the information you share on our website is stored and used.</p>
    <p>In particular:</p>
    <ul>
      <li>Age </li>
      <li>Language </li>
      <li>Gender </li>
      <li>Test results</li>
      <li>Datetime of submitted test</li>
    </ul>
    <br>
    <p><strong>Your IP, browser info and fingerprint is <i>not</i> stored</strong></p>
    <p>Google Analytics measures traffic to the site and how users interact the site.</p>
    <p>The Google Analytics terms specify that no personally identifiable information may be collected through the Google Analytics software.
        Read more about Google Analytics privacy policy <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">here</a>.</p>
    <p>No personal data is sold to anyone. Never have, never will.</p>
    <p>If you have any questions about this privacy policy, please let me know <a href='mailto:freeoceantesting@gmail.com' rel='noopener noreferrer' target='_blank'>here</a>.</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Private Policy for the Big 5 Personality Traits',
    titleTemplate: '%s | Free Personality Test',
    meta: [
        { vmid: 'og:url', property: 'og:url', content: 'https://bigfivepersonalitytraits.com/policy' },
    ],
    link: [
      { vmid: 'canonical', rel: 'canonical', href: 'https://bigfivepersonalitytraits.com/policy'}
    ]
  }
};
</script>